var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/sentry/tunnel";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"78c74b6fabc575911a073c54538f53fc032be04d"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { BrowserTracing, Replay, init } from "@sentry/nextjs";
import { config } from "./src/utils/config";

const environmentString =
  config.environment == "preview" ? `${config.environment}-${config.branch}` : config.environment;

if (!config.sentryEnabled) {
  console.log("Sentry disabled");
} else {
  init({
    dsn: config.sentryDsnUrl,
    environment: environmentString,
    integrations: (defaultIntegrations) => [...defaultIntegrations, new BrowserTracing(), new Replay()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
  });
}

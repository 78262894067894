import { array, boolean, literal, object, string, union } from "myzod";

const errorSchema = object({
  result: literal("error"),
  message: string(),
});

export const resultSchema = union([
  errorSchema,
  object({
    result: literal("success"),
    message: string().optional(),
  }),
]);

export const isSuperuserSchema = union([
  errorSchema,
  object({
    result: literal("success"),
    isSuperuser: boolean(),
  }),
]);

export const enabledFeatureFlagsSchema = union([
  errorSchema,
  object({
    result: literal("success"),
    keys: array(string()),
  }),
]);

export const devSongsSchema = object({
  devSongs: array(
    object({
      label: string(),
      files: array(string()),
    })
  )
})

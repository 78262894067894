import { Timestamp } from "../../types";
import { TransportContext } from "../../providers/TransportProvider";
import { useAmplitude, useGlobalState } from "..";
import { useCallback, useContext, useState } from "react";
import { useSong } from "../../api";

export function useTransport() {
  const { playing, setPlaying, duration, reportEndPosition, setStartPosition, currentPosition, setCurrentPosition, lastSeek, setLastSeek } =
    useContext(TransportContext);
  const { songId, muteCount, soloCount } = useGlobalState();
  const { data } = useSong(songId);
  const { logEvent, events } = useAmplitude();
  const [playAfterScrub, setPlayAfterScrub] = useState<boolean>(false);

  const onSeek = useCallback(
    (pos: Timestamp) => {
      setStartPosition(pos);
      setLastSeek(Math.round(new Date().getTime() / 100));
    },
    [setStartPosition, setLastSeek]
  );

  const onStartScrub = useCallback(() => {
    if (playing) {
      setPlaying(false);
      setPlayAfterScrub(true);
    }
  }, [playing, setPlaying]);

  const onEndScrub = useCallback(() => {
    if (playAfterScrub) {
      setPlaying(true);
      setPlayAfterScrub(false);
    }
  }, [playAfterScrub, setPlaying]);

  const onPlay = useCallback(() => {
    setPlaying(true);
    if (data)
      logEvent(
        events.song.playSong({
          songId: songId,
          songName: data.name,
          trackCount: data.channels.length,
          muteCount,
          soloCount,
        })
      );
  }, [data, events, logEvent, muteCount, setPlaying, soloCount, songId]);

  const onPause = useCallback(() => {
    // TODO: this up to transport provider?
    setStartPosition(currentPosition);
    setPlaying(false);
    if (data)
      logEvent(
        events.song.pauseSong({
          songId: songId,
          songName: data.name,
          trackCount: data.channels.length,
          muteCount,
          soloCount,
        })
      );
  }, [setStartPosition, currentPosition, setPlaying, data, logEvent, events, songId, muteCount, soloCount]);

  const onStop = useCallback(() => {
    setPlaying(false);
    setStartPosition(0);
    setCurrentPosition(0);
    if (data)
      logEvent(
        events.song.stopSong({
          songId: songId,
          songName: data.name,
          trackCount: data.channels.length,
          muteCount,
          soloCount,
        })
      );
  }, [data, events, logEvent, muteCount, setPlaying, setStartPosition, setCurrentPosition, soloCount, songId]);

  return {
    playing,
    duration,
    currentPosition,
    lastSeek,
    reportEndPosition,
    onPlay,
    onPause,
    onStop,
    onSeek,
    onStartScrub,
    onEndScrub,
  };
}

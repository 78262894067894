import { ApiValidationError } from "../errors";
import { IncomingMessage } from "http";
import { Type, ValidationError } from "myzod";
import { supabase } from "../utils";
import { useSecret, useSecretClient } from "../hooks";
import axios, { AxiosProgressEvent, AxiosRequestConfig, Method, ResponseType } from "axios";
import axiosRetry from "axios-retry";

async function parseAndValidateWithSchema<T>(schema: Type<T>, data: unknown) {
  const result = schema.try(data);
  if (result instanceof ValidationError) throw new ApiValidationError(result);
  return result;
}

export const useSupabaseAPIClient = ({ allowSecret = false }: { allowSecret?: boolean } = {}) => {
  const secret = useSecret();
  const { getSecretClient } = useSecretClient()

  if (secret && allowSecret) {
    return getSecretClient(secret)
  } else {
    return supabase;
  }
};

export async function request<T>({
  path,
  method,
  body,
  schema,
  params,
  responseType,
  onUploadProgress,
  onDownloadProgress,
}: {
  req?: IncomingMessage;
  path: string;
  method: Method;
  body?: Object;
  params?: Object;
  schema?: any;
  responseType?: ResponseType;
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
  onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void;
}): Promise<[T, string | null]> {
  const url = path;

  const requestConfig: AxiosRequestConfig = {
    method,
    url,
    data: body,
    params,
    responseType,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    onUploadProgress,
    onDownloadProgress,
  };

  try {
    const client = axios.create();
    axiosRetry(client, { retries: 3 });

    const response = await client(requestConfig)

    if (response.status === 204) {
      return [null as T, null];
    }

    const data = schema ? parseAndValidateWithSchema<T>(schema, response.data) : response.data

    let contentType = response.headers["Content-Type"]?.toString() || null;

    return [data, contentType]
  } catch (e) {
    if (e.response) {
      // Redirect all 401s and return
      // if (status === 401) {
      //   if (process.env.NODE_ENV === "development") {
      //     console.log("Redirecting to login", url);
      //   }
      //   return window.location.assign(getLoginUrl(window.location.toString()));
      // }
      // Redirect all 403s and return
      // if (status === 403) {
      //   if (process.env.NODE_ENV === "development") {
      //     console.log("Redirecting to 403 error page");
      //   }
      //   return window.location.assign("/app/error-403");
      // }
      // Send error notification if a non-403/404(/401)
      // if (status !== 403 && status !== 404) {
      //   reportError(e);
      // }
      if (process.env.NODE_ENV === "development") {
        console.log("Response error", e);
      }
    } else if (e.request) {
      if (process.env.NODE_ENV === "development") {
        console.log("Request error", e);
      }
    } else {
      if (process.env.NODE_ENV === "development") {
        console.log("Unknown error", e);
      }
    }

    // TODO: handle different errors differently
    // Rethrow all non-401s
    throw e;
  }
}

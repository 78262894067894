import { paths } from "../../utils";
import { useRouter } from "next/router";

export function useSecret(): string | undefined {
  const router = useRouter();

  if (router.pathname === paths.demo) {
    return "demo";
  }

  if ("s" in router.query) {
    return router.query.s as string;
  }

  return undefined;
}

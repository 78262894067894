import { DEFAULT_VOLUME } from "../constants";
import { StorageContext } from "./StorageProvider";
import { log } from "../utils";
import { useWaitForStable } from "../hooks";
import React, { ReactNode, createContext, useContext, useEffect, useState } from "react";

const CACHE_VERSION = "v2";

type ContextValue = {
  songId: string;
  globalState: GlobalState;
  channelState: { [channelId: string]: ChannelState };
  updateGlobalState: (partial: Partial<GlobalState>) => void;
  updateChannelState: (channelId: string, partial: Partial<ChannelState>) => void;
};

export interface ChannelState {
  solo: boolean;
  muted: boolean;
  volume: number;
}

export const initialChannelState: ChannelState = {
  solo: false,
  muted: false,
  volume: DEFAULT_VOLUME,
};

export interface GlobalState {
  masterVolume: number;
}

const initialGlobalState: GlobalState = {
  masterVolume: DEFAULT_VOLUME,
};

export const SongStateContext = createContext<ContextValue>({
  songId: "",
  globalState: initialGlobalState,
  channelState: {},
  updateGlobalState: (_: GlobalState) => { },
  updateChannelState: (_: string, __: ChannelState) => { },
});

type Props = {
  songId: string;
  children: ReactNode;
};

export const SongStateProvider = ({ songId, children }: Props) => {
  const [globalState, setGlobalState] = useState<GlobalState>(initialGlobalState);
  const [channelState, setChannelState] = useState<{ [channelId: string]: ChannelState }>({});
  const [loaded, setLoaded] = useState<boolean>(false);
  const waitForStable = useWaitForStable()

  const { getItem, setItem, removeItem } = useContext(StorageContext);

  const cacheKey = `song-state-${songId}-${CACHE_VERSION}`;

  useEffect(() => {
    const load = async () => {
      try {
        const initial = await getItem(cacheKey);

        if (initial !== null) {
          setGlobalState(initial.global);
          setChannelState(initial.channels);
        } else {
          // If item is not found in cache, it might exist in a now-deprecated key. We don't care about
          // throwing out song state values, so we just remove rather than migrate data.
          log("Removing old song state cache keys")
          [`song-state-${songId}-v1`].forEach((key) => removeItem(key))
        }
      } catch {
        // In case of an error, remove the item from the catch - likely to be a deserialisation issue
        removeItem(cacheKey);
      } finally {
        setLoaded(true);
      }
    };
    if (!loaded && cacheKey) load();
  }, [songId, cacheKey, getItem, loaded, removeItem]);

  useEffect(() => {
    if (loaded) {
      const currentState = {
        global: globalState,
        channels: channelState,
      };

      waitForStable(() => {
        setItem(cacheKey, currentState);
      }, 500)
    }
  }, [cacheKey, waitForStable, loaded, setItem, globalState, channelState])

  // Ensure initial state is loaded before children are rendered, so that initial values can be
  // correctly set
  if (!loaded) {
    return null;
  }

  const updateChannelState = (channelId: string, state: ChannelState) => {
    setChannelState((previous) => {
      return {
        ...previous,
        [channelId]: state,
      };
    });
  };

  const updateGlobalState = (state: GlobalState) => {
    setGlobalState(state);
  };

  return (
    <SongStateContext.Provider value={{ songId, channelState, globalState, updateChannelState, updateGlobalState }}>
      {children}
    </SongStateContext.Provider>
  );
};

export default SongStateProvider;

import { Database } from "../types/db"
import { SupabaseClient } from "@supabase/supabase-js";
import React, { ReactNode, createContext, useCallback, useState } from "react";

type ClientMap = Map<string, SupabaseClient<Database>>

type ContextValue = {
  secretClients: ClientMap;
  addSecretClient: (secret: string, client: SupabaseClient<Database>) => void;
};

export const SecretClientContext = createContext<ContextValue>({
  secretClients: new Map,
  addSecretClient: (_: string) => { },
});

type Props = {
  children: ReactNode;
};

export function SecretClientProvider({ children }: Props) {
  const [secretClients, setSecretClients] = useState<ClientMap>(new Map<string, SupabaseClient<Database>>);

  const addSecretClient = useCallback((secret: string, client: SupabaseClient<Database>) => {
    setSecretClients((prev) => {
      prev[secret] = client
      return prev
    })
  }, [])

  return <SecretClientContext.Provider value={{ secretClients, addSecretClient }}>{children}</SecretClientContext.Provider>;
}

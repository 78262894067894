import React, { ReactNode, createContext, useState } from "react";

type ContextValue = {
  title: string | undefined;
  setTitle: (title: string | undefined) => void;
};

export const TitleContext = createContext<ContextValue>({
  title: undefined,
  setTitle: (_: string | undefined) => {},
});

type Props = {
  children: ReactNode;
};

export function TitleProvider({ children }: Props) {
  const [title, setTitle] = useState<string | undefined>();

  return <TitleContext.Provider value={{ title, setTitle }}>{children}</TitleContext.Provider>;
}

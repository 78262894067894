import { log } from "../utils";
import { reportError } from "../utils/errorReporting";
import React, { ReactNode, createContext, useCallback, useEffect, useState } from "react";

const STORE_NAME = "trackshare-cache";

type ContextValue = {
  getItem: (key: string, deprecatedKey?: string) => any;
  setItem: (key: string, item: any) => any;
  removeItem: (key: string) => any;
  clear: () => void;
};

export const StorageContext = createContext<ContextValue>({
  getItem: (_: string, __?: string) => { },
  setItem: (_: string, __: any) => { },
  removeItem: (_: string) => { },
  clear: () => { },
});

type Props = {
  children: ReactNode;
};

export function StorageProvider({ children }: Props) {
  const [store, setStore] = useState<LocalForage | undefined>();

  useEffect(() => {
    const load = async () => {
      const localforage = (await import('localforage')).default

      setStore(
        localforage.createInstance({
          driver: [localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE],
          name: STORE_NAME,
        })
      );
    }
    load()
  }, []);

  const getItem = useCallback(
    async (key: string, deprecatedKey?: string) => {
      if (store === undefined) return null;

      try {
        const value = await store.getItem(key);

        if (value !== null) {
          return value;
        } else if (value === null && deprecatedKey !== undefined) {
          const valueToMigrate = await store.getItem(deprecatedKey);
          if (valueToMigrate !== null) {
            await store.setItem(key, valueToMigrate)
            await store.removeItem(deprecatedKey)

            log(`Migrated local storage key ${deprecatedKey} to ${key}`)

            return valueToMigrate
          } else {
            return null;
          }
        } else {
          return null;
        }
      } catch (error) {
        reportError(error);
        return null;
      }
    },
    [store]
  );

  const setItem = useCallback(
    async (key: string, item: any) => {
      if (store === undefined) return;

      try {
        return await store.setItem(key, item);
      } catch (error) {
        reportError(error);
      }
    },
    [store]
  );

  const removeItem = useCallback(
    async (key: string) => {
      if (store === undefined) return;

      try {
        return await store.removeItem(key);
      } catch (error) {
        reportError(error);
      }
    },
    [store]
  );

  const clear = useCallback(async () => {
    if (store === undefined) return null;

    try {
      return await store.clear();
    } catch (error) {
      reportError(error);
    }
  }, [store]);

  return <StorageContext.Provider value={{ getItem, setItem, removeItem, clear }}>{children}</StorageContext.Provider>;
}

export const promiseMapSeries = async <T, R>(array: Array<T>, cb: (item: T, index: number, array: Array<T>) => Promise<R> | R): Promise<R[]> => {
  const length = array.length;
  const results: Array<R> = new Array(length);

  for (let i = 0; i < length; ++i) {
    results[i] = await cb(array[i], i, array);
  }

  return results;
}

import { Database } from "../types/db"
import { SupabaseClient, createClient } from "@supabase/supabase-js";

import { config } from ".";
import { createBrowserSupabaseClient } from "@supabase/auth-helpers-shared";

export const supabase: SupabaseClient<Database> = createBrowserSupabaseClient<Database>({
  supabaseUrl: config.supabaseUrl, supabaseKey: config.supabaseAnonKey
});

// TODO: User auth helpers?
export const supabaseSuperUser = config.supabaseServiceRoleKey
  ? createClient<Database>(config.supabaseUrl, config.supabaseServiceRoleKey, { auth: { persistSession: false } })
  : undefined;

export const secretClient = (secret: string): SupabaseClient<Database> => {
  const options = {
    auth: { storageKey: `sb-${new URL(config.supabaseUrl).hostname.split('.')[0]}-auth-token-${secret}` },
    global: { headers: { Secret: secret } }
  }

  return createBrowserSupabaseClient<Database>({
    supabaseUrl: config.supabaseUrl, supabaseKey: config.supabaseAnonKey, options
  });
};

import { AudioContextNotReadyError } from "../errors";
import { AudioPlayEvent, AudioStopEvent } from "../events";
import { Timestamp } from "../types";
import { useAudioContext } from "../hooks";
import { useInterval } from "@chakra-ui/react";
import React, { ReactNode, createContext, useCallback, useEffect, useState } from "react";

type ContextValue = {
  playing: boolean;
  setPlaying: (playing: boolean) => void;
  duration: number | undefined;
  reportEndPosition: (position: number) => void;
  setStartPosition: (duration: number) => void;
  currentPosition: number;
  setCurrentPosition: (duration: number) => void;
  lastSeek: number;
  setLastSeek: (timestamp: number) => void;
};

export const TransportContext = createContext<ContextValue>({
  playing: false,
  setPlaying: (_) => { },
  duration: undefined,
  reportEndPosition: (_) => { },
  setStartPosition: (_) => { },
  currentPosition: 0,
  setCurrentPosition: (_) => { },
  lastSeek: 0,
  setLastSeek: (_) => { },
});

type Props = {
  children: ReactNode;
};

export function TransportProvider({ children }: Props) {
  const [playing, setPlaying] = useState(false);
  const [startPosition, setStartPosition] = useState<Timestamp>(0);
  const [currentPosition, setCurrentPosition] = useState<Timestamp>(0);
  const [duration, setDuration] = useState<Timestamp | undefined>(undefined);
  const [lastSeek, setLastSeek] = useState<Timestamp>(0);
  const { audioContext } = useAudioContext();

  const [startedAtMs, setStartedAtMs] = useState<number | undefined>(undefined);

  const updateCurrentPosition = useCallback(() => {
    if (!duration || !startedAtMs) return;
    const elapsedSeconds = (performance.now() - startedAtMs) / 1000;
    const position = startPosition + elapsedSeconds;
    setCurrentPosition(position);

    if (position >= duration) {
      setPlaying(false);
      setStartPosition(0);
    }
  }, [startPosition, duration, startedAtMs]);

  useInterval(() => updateCurrentPosition(), playing ? 50 : null);

  const onPlay = useCallback(
    (position: Timestamp) => {
      if (!audioContext) throw new AudioContextNotReadyError();

      document.dispatchEvent(AudioPlayEvent.new({
        currentTime: audioContext.currentTime,
        startPosition: position
      }));
    },
    [audioContext]
  );

  const onStop = useCallback(() => {
    document.dispatchEvent(AudioStopEvent.new());
  }, []);

  const reportEndPosition = useCallback((position: Timestamp) => {
    setDuration((previous) => {
      if (previous === undefined) {
        return position;
      } else {
        return position > previous ? position : previous;
      }
    });
  }, []);

  useEffect(() => {
    if (playing) {
      onPlay(startPosition);
      setStartedAtMs(performance.now());
    } else {
      onStop();
      setStartedAtMs(undefined);
    }
  }, [lastSeek, startPosition, playing, onPlay, onStop]);

  useEffect(() => {
    setStartedAtMs(performance.now());
    setCurrentPosition(startPosition);
  }, [startPosition, lastSeek]);

  if (!audioContext) return null;

  return (
    <TransportContext.Provider
      value={{
        playing,
        setPlaying,
        duration,
        reportEndPosition,
        setStartPosition,
        currentPosition,
        setCurrentPosition,
        lastSeek,
        setLastSeek,
      }}
    >
      {children}
    </TransportContext.Provider>
  );
}

import { Notification } from "../../types";
import { ToastId, ToastPositionWithLogical, useBreakpointValue, useToast } from "@chakra-ui/react";
import { useCallback } from "react";

export function useNotifications(): [
  (notification: Notification) => ToastId | undefined,
  (id: ToastId) => void,
  () => void
] {
  const toast = useToast();
  const position: ToastPositionWithLogical = useBreakpointValue({ base: "bottom", md: "top-right" }) || "top-right";

  const add = useCallback(
    ({ title, message, type, autohide = true }: Notification): ToastId | undefined => {
      const id = toast({
        // containerStyle:
        //   position === "top-right"
        //     ? {
        //         marginTop: "80px",
        //       }
        //     : {},
        position,
        title: title,
        description: message,
        status: type,
        isClosable: true,
        duration: autohide ? 5000 : null,
      });
      return id;
    },
    [toast, position]
  );

  const remove = useCallback(
    (id: ToastId) => {
      toast.close(id);
    },
    [toast]
  );

  const clear = useCallback(() => {
    toast.closeAll();
  }, [toast]);

  return [add, remove, clear];
}

import { GetServerSideProps, GetServerSidePropsContext } from "next"
import { createServerSupabaseClient } from "@supabase/auth-helpers-nextjs"
import { paths } from "./paths"
import merge from "lodash/merge";

export const withPageAuth = ({ getServerSideProps }: { getServerSideProps?: GetServerSideProps }) => async (ctx: GetServerSidePropsContext) => {
  // Create authenticated Supabase Client
  const supabase = createServerSupabaseClient(ctx)
  // Check if we have a session
  const {
    data: { session },
  } = await supabase.auth.getSession()

  if (!session)
    return {
      redirect: {
        destination: paths.signIn,
        permanent: false,
      },
    }

  const pageProps = getServerSideProps ? await getServerSideProps(ctx) : {}

  const authProps = {
    props: {
      initialSession: session,
      user: session.user,
    }
  }

  return merge(authProps, pageProps)
}

type Environment = "production" | "preview" | "development";

interface Config {
  baseUrl: string;
  domain: string;
  application: string;
  bucket: string;
  supabaseUrl: string;
  supabaseAnonKey: string;
  supabaseServiceRoleKey: string | undefined;
  sentryDsnUrl: string | undefined;
  sentryEnabled: boolean;
  branch: string;
  environment: Environment;
  registrationsClosed: boolean;
  demoSongId: string;
  amplitudeApiKey: string;
}

const getConfig = (): Config => {
  return {
    baseUrl: process.env.BASE_URL!,
    domain: process.env.DOMAIN!,
    application: process.env.APPLICATION!,
    bucket: process.env.NEXT_PUBLIC_BUCKET!,
    supabaseUrl: process.env.NEXT_PUBLIC_SUPABASE_URL!,
    supabaseAnonKey: process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
    supabaseServiceRoleKey: process.env.SUPABASE_SERVICE_ROLE_KEY,
    sentryDsnUrl: process.env.SENTRY_DSN_URL,
    sentryEnabled: process.env.SENTRY_ENABLED === "true",
    branch: process.env.BRANCH!,
    environment: process.env.ENVIRONMENT as Environment,
    registrationsClosed: process.env.NEXT_PUBLIC_REGISTRATIONS_CLOSED === "true",
    demoSongId: process.env.NEXT_PUBLIC_DEMO_SONG_ID!,
    amplitudeApiKey: process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY!,
  };
};

export const config = getConfig();

interface ServerConfig extends Config {
  sendgridApiKey: string | undefined;
  sendgridUserListId: string | undefined;
  sendgridWaitlistListId: string | undefined;
  sendgridEnabled: boolean;
}

const getServerConfig = (): ServerConfig => {
  return {
    ...config,
    sendgridApiKey: process.env.SENDGRID_API_KEY,
    sendgridUserListId: process.env.SENDGRID_USER_LIST_ID,
    sendgridWaitlistListId: process.env.SENDGRID_WAITLIST_LIST_ID,
    sendgridEnabled: process.env.SENDGRID_ENABLED === "true",
  };
};

export const serverConfig = getServerConfig();

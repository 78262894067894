import { generateSharingSecret } from "../../utils";
import { updatePlaylist } from "../../api";
import { useNotifications } from "..";

export function useSharePlaylist() {
  const [addNotification] = useNotifications();

  const resetSharing = async (id: string) => {
    try {
      await updatePlaylist(id, { secret: generateSharingSecret() });
    } catch (error) {
      addNotification({
        type: "error",
        message: "Failed to reset sharing",
        autohide: true,
      });

      throw error;
    }
  };

  const disableSharing = async (id: string) => {
    try {
      await updatePlaylist(id, { secret: null });
    } catch (error) {
      addNotification({
        type: "error",
        message: "Failed to disable sharing",
        autohide: true,
      });

      throw error;
    }
  };

  return { resetSharing, disableSharing };
}

import { useAmplitude } from "../hooks";
import { useSong } from "../api";
import React, { ReactNode, createContext, useCallback, useEffect, useRef, useState } from "react";

type ContextValue = {
  ready: boolean;
  onStartChannelLoad: () => Promise<void>;
  onChannelLoad: () => Promise<void>;
};

export const LoadTrackerContext = createContext<ContextValue>({
  ready: false,
  onStartChannelLoad: async () => { },
  onChannelLoad: async () => { },
});

type Props = {
  songId: string;
  children: ReactNode;
};

export function LoadTrackerProvider({ songId, children }: Props) {
  const [ready, setReady] = useState(false);
  const { data } = useSong(songId);
  const { logEvent, events } = useAmplitude();
  const [loadStartTime, setLoadStartTime] = useState<number | undefined>(undefined);

  const loadedCount = useRef(0);

  useEffect(() => {
    if (ready && loadStartTime && data) {
      const loadDurationSeconds = Math.ceil((new Date().getTime() - loadStartTime) / 1000);

      logEvent(events.song.completeLoad({ songId, songName: data.name, loadDurationSeconds }));
    }
  }, [ready, loadStartTime, data, songId, events, logEvent]);

  const onStartChannelLoad = useCallback(async () => {
    if (!loadStartTime) {
      setLoadStartTime(new Date().getTime());
    }
  }, [loadStartTime]);

  const onChannelLoad = useCallback(async () => {
    if (!data) return;

    loadedCount.current += 1;

    if (loadedCount.current === data.channels.length) {
      setReady(true);
    }
  }, [data]);

  return (
    <LoadTrackerContext.Provider
      value={{
        ready,
        onStartChannelLoad,
        onChannelLoad,
      }}
    >
      {data ? children : null}
    </LoadTrackerContext.Provider>
  );
}

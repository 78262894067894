import { useCallback, useRef } from "react";

export const useWaitForStable = () => {
  const savedTimer = useRef<NodeJS.Timeout | undefined>();

  const waitForStable = useCallback((callback: () => void, ms: number) => {
    if (savedTimer.current !== undefined) {
      clearTimeout(savedTimer.current);
    }

    const timer = setTimeout(() => {
      callback();
      savedTimer.current = undefined;
    }, ms);

    savedTimer.current = timer;

    return () => {
      if (savedTimer.current !== undefined) clearTimeout(savedTimer.current);
    };
  }, []);

  return waitForStable
};

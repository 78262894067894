import { AxiosProgressEvent } from "axios";
import { getBlob } from "../../api";
import { useAmplitude, useStorage } from "..";
import { useCallback } from "react";

const CACHE_VERSION = "v2";

export function useRegionStorage() {
  const { getItem, setItem } = useStorage();
  const { logEvent, events } = useAmplitude();

  const getKey = (id: string): string => {
    return `region-${id}-${CACHE_VERSION}`;
  };

  // Deprecated key - only used for migrations
  const getDeprecatedKey = (id: string): string => {
    return `track-${id}-${CACHE_VERSION}`;
  };

  const getRegion = useCallback(
    async (id: string, songId: string, source: string, onProgress: (e: AxiosProgressEvent) => void): Promise<Blob> => {
      const key = getKey(id);
      const deprecatedKey = getDeprecatedKey(id);
      var region: Blob | null = await getItem(key, deprecatedKey);

      if (region === null) {
        region = await getBlob(source, onProgress);
        setItem(key, region);
        logEvent(events.song.loadTrack({ trackId: id, songId: songId, fromCache: false }));
      } else {
        logEvent(events.song.loadTrack({ trackId: id, songId: songId, fromCache: true }));
      }

      return region;
    },
    [events, getItem, logEvent, setItem]
  );

  return { getRegion };
}

import { updatePlaylist } from "../../api";
import { useNotifications } from "..";

export function useRenamePlaylist() {
  const [addNotification] = useNotifications();

  const renamePlaylist = async (id: string, name: string) => {
    try {
      await updatePlaylist(id, { name });
    } catch (error) {
      addNotification({
        type: "error",
        message: "Failed to rename playlist",
        autohide: true,
      });

      throw error;
    }
  };

  return renamePlaylist;
}

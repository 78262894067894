import { updatePlaylist } from "../../api";
import { useNotifications } from "..";

export function useDeletePlaylist() {
  const [addNotification] = useNotifications();

  const deletePlaylist = async (id: string) => {
    try {
      await updatePlaylist(id, { status: "deleted", deletedAt: new Date().toISOString() });
    } catch (error) {
      addNotification({
        type: "error",
        message: "Failed to delete playlist",
        autohide: true,
      });

      throw error;
    }
  };

  return deletePlaylist;
}

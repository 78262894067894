import { AuthError, PostgrestError } from "@supabase/supabase-js";

export class ApiValidationError extends Error {
  constructor(...params: any[]) {
    super(...params);
    Object.setPrototypeOf(this, ApiValidationError.prototype);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiValidationError);
    }
    this.name = "ApiValidationError";
  }
}

export class ResponseError extends Error {
  constructor(public response: Response) {
    super("Request returned a non-2xx status code");
    Object.setPrototypeOf(this, ResponseError.prototype);
    this.name = "ResponseError";
  }
}

export class NotFoundError extends Error {
  constructor(msg: string) {
    super(msg);
    Object.setPrototypeOf(this, NotFoundError.prototype);
    this.name = "NotFoundError";
  }
}

export class NotAuthenticatedError extends Error {
  constructor(msg: string) {
    super(msg);
    Object.setPrototypeOf(this, NotAuthenticatedError.prototype);
    this.name = "NotAuthenticatedError";
  }
}

export class SupabasePostgrestError extends Error {
  public message: string;
  public details: string | null;
  public hint: string | null;
  public code: string | null;

  constructor({ message, details, hint, code }: PostgrestError) {
    super();
    Object.setPrototypeOf(this, SupabasePostgrestError.prototype);
    this.name = "SupabasePostgrestError";
    this.message = message;
    this.details = details;
    this.hint = hint;
    this.code = code;
  }
}
export class SupabaseAuthError extends Error {
  constructor(error: AuthError) {
    super();
    Object.setPrototypeOf(this, SupabaseAuthError.prototype);
    this.name = "GoTrueApiError";
    this.message = error.message;
  }
}

export class AudioContextNotReadyError extends Error {
  constructor() {
    super();
    Object.setPrototypeOf(this, AudioContextNotReadyError.prototype);
    this.name = "AudioContextNotReadyError";
  }
}

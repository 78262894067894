import { insertPlaylistSong } from "../../api";
import { supabase } from "../../utils";
import { useNotifications } from "..";

export function useAddSongToPlaylist() {
  const [addNotification] = useNotifications();

  const addSongToPlaylist = async (songId: string, playlistId: string): Promise<Boolean> => {
    try {
      const songsInPlaylist = await getSongsInPlaylist(playlistId);

      if (songsInPlaylist.includes(songId)) {
        addNotification({
          type: "warning",
          message: "Song already in playlist",
          autohide: true,
        });

        return false;
      } else {
        const order = songsInPlaylist.length + 1;
        await insertPlaylistSong({ playlistId, songId, order });

        return true;
      }
    } catch (error) {
      addNotification({
        type: "error",
        message: "Failed to add song to playlist",
        autohide: false,
      });

      throw error;
    }
  };

  const getSongsInPlaylist = async (playlistId: string) => {
    const { data, error } = await supabase
      .from("playlists_songs")
      .select(`songId`)
      .eq("playlistId", playlistId);

    if (error || !data) {
      throw error;
    }

    return data.map((ps) => ps.songId);
  };

  return addSongToPlaylist;
}

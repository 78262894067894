import { AudioMetadata } from "../../types";
import { FFmpeg, createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
import { getAbsolutePath, getAudioMetadata, log, promiseMapSeries } from "../../utils";
import { useAudioContext, useQueryParam } from "../";
import { v4 } from "uuid";
import chunk from "lodash/chunk";
import sumBy from "lodash/sumBy";

const SILENCE_BUFFER = 0.5 // seconds
const END_OF_FILE_TOLERANCE = 0.1 // seconds
const SILENCE_DETECTION_DURATION = 2 // seconds. ensure this is > SILENCE_BUFFER * 2
const SILENCE_DETECTION_THRESHOLD = -80 // dB. Default is -60

interface EncodingOptions {
  // numberOfChannels?: 1 | 2;
  encoding?: "cbr" | "vbr";
  codec: "mp3"
  sampleRate: 44100
}

// Operate with strings to maintain precision
type Period = { start: number, end: number }

type FileWithPosition = { file: File, position: number }

// TODO: Does wavesurfer.js etc support variable bitrates?
const getVBROption = (duration: number) => {
  // https://trac.ffmpeg.org/wiki/Encode/MP3
  if (duration > 600) {
    // Over 10 minutes -> ~85 kbit/s
    return ["-q:a", "8"];
  } else if (duration > 300) {
    // Over 5 minutes -> ~100 kbit/s
    return ["-q:a", "7"];
  } else {
    // Under 5 minutes -> ~115 kbit/s
    return ["-q:a", "6"];
  }
};

const getCBROption = (duration: number) => {
  // https://trac.ffmpeg.org/wiki/Encode/MP3
  // 8, 16, 24, 32, 40, 48, 64, 80, 96, 112, 128, 160, 192, 224, 256, or 320
  if (duration > 600) {
    // Over 10 minutes
    return ["-b:a", "64k"];
  } else if (duration > 300) {
    // Over 5 minutes
    return ["-b:a", "80k"];
  } else {
    // Under 5 minutes
    return ["-b:a", "96k"];
  }
};

const detectSilence = async (ffmpeg: FFmpeg, inputFile: File): Promise<Period[]> => {
  var logLines: string[] = [];

  // Only pull out silencedetect lines
  ffmpeg.setLogger(({ message }) => {
    if (message.match(/^\[silencedetect/)) {
      logLines.push(message)
    }
  })

  await ffmpeg.run(...["-hide_banner", "-nostats", "-i", inputFile.name, "-af", `silencedetect=n=${SILENCE_DETECTION_THRESHOLD.toString()}dB:d=${SILENCE_DETECTION_DURATION.toString()}`, "-f", "null", "-"]);

  // Remove logger
  ffmpeg.setLogger(() => { })

  var silencePeriods: Period[] = [];
  var currentStart: string | null = null
  var currentEnd: string | null = null

  // [silencedetect @ 0x600000e571e0] silence_start: 0
  // [silencedetect @ 0x600000e571e0] silence_end: 39.3249 | silence_duration: 39.3249
  logLines.forEach(line => {
    const startMatches = line.match(/silence_start: (\d+.?\d*)/)
    if (startMatches) {
      currentStart = startMatches[1]
      if (currentEnd !== null) throw new Error("currentEnd unexpectedly not null")
    }

    const endMatches = line.match(/silence_end: (\d+.?\d*)/)
    if (endMatches) {
      currentEnd = endMatches[1]
      if (currentStart === null) throw new Error("currentStart unexpectedly null")
    }

    if (currentStart !== null && currentEnd !== null) {
      silencePeriods.push({ start: parseFloat(currentStart), end: parseFloat(currentEnd) })
      currentStart = null;
      currentEnd = null;
    }
  })

  if (currentStart !== null || currentEnd !== null) throw new Error("currentStart or currentEnd unexpectedly not null")

  log("detectSilence", inputFile.name, silencePeriods);

  return silencePeriods;
}

const getEncodeArgs = (input: string, output: string, startTimestamp: number, endTimestamp: number, encodingOption: string[], numberOfChannels: number, codec: "mp3", sampleRate: 44100) => {
  var args: string[] = []

  args.push(...["-ss", startTimestamp.toString(), "-to", endTimestamp.toString()])

  args.push(...["-i", input, "-vn", "-ar", sampleRate.toString(), "-ac", numberOfChannels.toString(), "-c:a", codec, ...encodingOption, output])

  return args;
};

const encode = async (ffmpeg: FFmpeg, inputFile: File, startTimestamp: number, endTimestamp: number, metadata: AudioMetadata, options: EncodingOptions): Promise<File> => {
  const { encoding = "vbr", codec, sampleRate } = options;

  const encodingOption =
    encoding === "vbr" ? getVBROption(metadata.duration) : getCBROption(metadata.duration);

  const tempFileName = `${v4()}.mp3`;

  const encodeArgs = getEncodeArgs(inputFile.name, tempFileName, startTimestamp, endTimestamp, encodingOption, metadata.numberOfChannels, codec, sampleRate)
  log("Running ffmpeg", encodeArgs.join(" "))
  await ffmpeg.run(...encodeArgs);

  const data = ffmpeg.FS("readFile", tempFileName);
  ffmpeg.FS("unlink", tempFileName);

  // Change extension to mp3
  const outputFileName = inputFile.name.replace(/\.[^/.]+$/, `.${codec}`)
  const outputFile = new File([data.buffer], outputFileName, {
    type: "audio/mp3",
    lastModified: inputFile.lastModified,
  });

  return outputFile;
}

const invertSilencePeriods = (silencePeriods: Period[], fileDuration: number): Period[] => {
  // If song starts with silence, first value with be "0"
  const timestampArray = silencePeriods.flatMap(silencePeriod => [silencePeriod.start, silencePeriod.end])

  if (timestampArray[0] == 0) {
    // If timestampArray starts with "0", song starts with silence -> remove first element
    timestampArray.shift()
  } else {
    // If timestampArray does not start with "0", song starts with region -> insert "0" as first element
    timestampArray.unshift(0)
  }

  if (Math.abs(timestampArray[timestampArray.length - 1] - fileDuration) <= END_OF_FILE_TOLERANCE) {
    // If final value is equal to file duration (within tolerance), song ends with silence -> remove final element
    timestampArray.pop()
  } else if (timestampArray[timestampArray.length - 1] > fileDuration) {
    // Sanity check to make sure final value is not higher than file duration
    throw new Error("Final value higher than file duration")
  } else {
    // If final value is not equal to file duration, song ends with region -> append duration as final element
    timestampArray.push(fileDuration)
  }

  // Chunk into pairs, turn into region periods
  return chunk(timestampArray, 2).map(([start, end]) => {
    const regionStart = Math.max(start - SILENCE_BUFFER, 0);
    const regionEnd = Math.min(end + SILENCE_BUFFER, fileDuration);

    return { start: regionStart, end: regionEnd }
  })
}

export const useTranscoder = () => {
  const { audioContext } = useAudioContext();
  const removeSilence = useQueryParam<string>("removeSilence", "1") == "1";

  const getFFmpeg = async (): Promise<FFmpeg> => {
    const ffmpeg = createFFmpeg({
      corePath: getAbsolutePath('/static/ffmpeg/ffmpeg-core.js'),
      log: false,
    });

    await ffmpeg.load();

    return ffmpeg;
  };

  const transcodeToRegions = async (inputFiles: File[], encodingOptions: EncodingOptions, onProgress: (progress: number) => void): Promise<FileWithPosition[][]> => {
    if (!audioContext) throw new Error("audioContext not initialized");

    const ffmpeg = await getFFmpeg();

    return await promiseMapSeries<File, FileWithPosition[]>(inputFiles, async (inputFile, index) => {
      log("Transcoding file", `${(index + 1)} / ${inputFiles.length}`)
      onProgress((index + 1) / inputFiles.length)

      ffmpeg.FS("writeFile", inputFile.name, await fetchFile(inputFile));

      const metadata = await getAudioMetadata(inputFile, audioContext);

      var regionPeriods: Period[];

      if (removeSilence) {
        const silencePeriods = await detectSilence(ffmpeg, inputFile);
        regionPeriods = invertSilencePeriods(silencePeriods, metadata.duration)
        log("regionPeriods", regionPeriods)
      } else {
        regionPeriods = [{ start: 0, end: metadata.duration }]
      }

      const regions = await promiseMapSeries<Period, FileWithPosition>(regionPeriods, async (regionPeriod): Promise<FileWithPosition> => {
        const outputFile = await encode(ffmpeg, inputFile, regionPeriod.start, regionPeriod.end, metadata, encodingOptions);

        return { file: outputFile, position: regionPeriod.start }
      })

      const compressedSize = sumBy(regions, r => r.file.size);
      log(`Compressed ${inputFile.name} from ${inputFile.size} to ${compressedSize} (${compressedSize * 100 / inputFile.size}%)`);

      // Delete file
      ffmpeg.FS("unlink", inputFile.name);

      return regions;
    })
  };

  return { transcodeToRegions };
};

import isUUID from "validator/lib/isUUID";
import short from "short-uuid";

export const normalizeUUID = (str: string, { allowString = false }: { allowString?: boolean }): string => {
  if (isUUID(str)) {
    return str;
  }
  try {
    return short().toUUID(str);
  } catch (e) {
    if (allowString ?? false) {
      return str;
    }

    throw e;
  }
};

export const shortenUUID = (uuid: string): string => {
  return short().fromUUID(uuid);
};

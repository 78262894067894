import { updateSong } from "../../api";
import { useNotifications } from "..";

export function useDeleteSong() {
  const [addNotification] = useNotifications();

  const deleteSong = async (id: string) => {
    try {
      await updateSong(id, { status: "deleted", deletedAt: new Date().toISOString() });
    } catch (error) {
      addNotification({
        type: "error",
        message: "Failed to delete song",
        autohide: true,
      });

      throw error;
    }
  };

  return deleteSong;
}

import { log } from "../../utils";
import { useCallback, useEffect, useState } from "react";
import { useSWRConfig } from "swr";
import { useStorage } from ".";

const CACHE_KEY = "swr-cache"

export const useSWRCache = () => {
  const { getItem, setItem } = useStorage();
  const { cache } = useSWRConfig();
  const [ready, setReady] = useState<boolean>(false);

  useEffect(() => {
    const load = async () => {
      try {
        const persistedCache: string | null = await getItem(CACHE_KEY)

        if (persistedCache) {
          const cachedEntries: object = JSON.parse(persistedCache);

          log("Loading SWR cache", cachedEntries)

          Object.entries(cachedEntries).forEach(([key, value]) => {
            if (!value) return;

            cache.set(key, value);
          });
        }
      } finally {
        setReady(true);
      }
    }

    if (!ready) load()
  }, [getItem, cache, ready]);

  const persistCache = useCallback(async () => {
    const newCache: any = {};

    for (const key of cache.keys()) {
      newCache[key] = cache.get(key);
    }

    log("Persisting SWR cache", newCache)

    setItem(CACHE_KEY, JSON.stringify(newCache));
  }, [cache, setItem])

  useEffect(() => {
    window.addEventListener("beforeunload", persistCache);

    return () => {
      window.removeEventListener("beforeunload", persistCache);
    };
  }, [persistCache]);

  return { ready };
};

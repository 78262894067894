import { removePlaylistSong } from "../../api";
import { useNotifications } from "..";

export function useRemoveSongFromPlaylist() {
  const [addNotification] = useNotifications();

  const removeSongFromPlaylist = async (songId: string, playlistId: string) => {
    try {
      await removePlaylistSong({ playlistId, songId });
    } catch (error) {
      addNotification({
        type: "error",
        message: "Failed to add song to playlist",
        autohide: true,
      });

      throw error;
    }
  };

  return removeSongFromPlaylist;
}

import { AudioMetadata } from "../types";

export const getAudioMetadata = async (file: File, audioContext: AudioContext): Promise<AudioMetadata> => {
  return new Promise(async (resolve, reject) => {
    try {
      const arrayBuffer = await file.arrayBuffer();

      audioContext.decodeAudioData(arrayBuffer, (buffer) => {
        const metadata = {
          duration: buffer.duration,
          sampleRate: buffer.sampleRate,
          numberOfChannels: buffer.numberOfChannels,
        };

        resolve(metadata);
      });
    } catch (e) {
      reject(e);
    }
  });
};

export const secondsToTimestamp = (s: number): string => {
  const minutes = Math.floor(s / 60);
  const seconds = Math.floor(s % 60);
  // const milliseconds = Math.floor((s % 1) * 1000);

  const padded = (num: number, length) => {
    return (new Array(1 + length).join("0") + num.toString()).slice(-length);
  };

  return `${padded(minutes, 2)}:${padded(seconds, 2)}`;
};

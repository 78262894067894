import { Timestamp } from "./types";

export const AudioPlayEvent = {
  name: "ts-play",
  new({ currentTime, startPosition }: { currentTime: Timestamp, startPosition: Timestamp }): CustomEvent<{ currentTime: Timestamp; startPosition: Timestamp }> {
    return new CustomEvent<{ currentTime: Timestamp; startPosition: Timestamp }>(AudioPlayEvent.name, { detail: { currentTime, startPosition } })
  }
}

export const AudioStopEvent = {
  name: "ts-stop",
  new(): CustomEvent<{}> {
    return new CustomEvent<{}>(AudioStopEvent.name)
  }
}

import { AmplitudeEvent, AmplitudePropertyType, IncrementalUserProperties, UserProperties } from "../amplitude";
import { config, log } from "../utils";
import { useAuth } from "../hooks";
import React, { ReactNode, createContext, useCallback, useEffect, useRef } from "react";
import amplitude from "amplitude-js";

type ContextValue = {
  setUserId: (userId: string | null) => void;
  logEvent: (e: AmplitudeEvent) => Promise<void>;
  incrementUserProperty: (property: IncrementalUserProperties, increment?: number) => Promise<void>;
  setUserProperties: (properties: { [Key in UserProperties]: AmplitudePropertyType }) => Promise<void>;
};

export const AmplitudeContext = createContext<ContextValue>({
  setUserId: () => {},
  logEvent: async () => {},
  incrementUserProperty: async () => {},
  setUserProperties: async () => {},
});

type Props = {
  children: ReactNode;
};

const amplitudeOptions: amplitude.Config = {
  includeUtm: true,
  includeReferrer: true,
  transport: "beacon",
  apiEndpoint: `${config.domain}/amplitude`,
  forceHttps: config.environment !== "development",
};

export function AmplitudeProvider({ children }: Props) {
  const { user } = useAuth();
  const initialized = useRef<boolean>(false);

  const getInstance = useCallback(() => {
    const instance = amplitude.getInstance();
    if (!initialized.current) {
      instance.init(config.amplitudeApiKey, undefined, amplitudeOptions);
      initialized.current = true;
      log("Amplitude initialized", amplitudeOptions);
    }

    return instance;
  }, []);

  const setUserId = useCallback(
    (userId: string | null) => {
      log("Amplitude user set", userId);
      getInstance().setUserId(userId);
    },
    [getInstance]
  );

  useEffect(() => {
    if (user) {
      setUserId(user.id);
    }
  }, [user, setUserId]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      getInstance().setUserProperties({
        viewportWidth: window.innerWidth,
        viewportHeight: window.innerHeight,
      });
    }
  }, [getInstance]);

  const logEvent = useCallback(
    async ({ event, data = {} }: AmplitudeEvent) => {
      function waitForLogEventCallback() {
        return new Promise<void>((resolve, reject) => {
          try {
            event = event.toLowerCase();
            log("Logging event:", event, data);
            getInstance().logEvent(event, data, () => {
              resolve();
            });
          } catch {
            reject();
          }
        });
      }
      await waitForLogEventCallback();
      return;
    },
    [getInstance]
  );

  const incrementUserProperty = useCallback(
    async (property: IncrementalUserProperties, increment: number = 1) => {
      function waitForLogEventCallback() {
        return new Promise<void>((resolve, reject) => {
          try {
            log("Incrementing user property:", property, increment);
            const identify = new amplitude.Identify().add(property, increment);
            getInstance().identify(identify, () => {
              resolve();
            });
          } catch {
            reject();
          }
        });
      }
      await waitForLogEventCallback();
      return;
    },
    [getInstance]
  );

  const setUserProperties = useCallback(
    async (properties: { [Key in UserProperties]: AmplitudePropertyType }) => {
      function waitForLogEventCallback() {
        return new Promise<void>((resolve, reject) => {
          try {
            log("Setting user properties:", properties);
            const identify = new amplitude.Identify();

            Object.keys(properties).forEach((key) => {
              identify.set(key, properties[key]);
            });
            getInstance().identify(identify, () => {
              resolve();
            });
          } catch {
            reject();
          }
        });
      }
      await waitForLogEventCallback();
      return;
    },
    [getInstance]
  );

  return (
    <AmplitudeContext.Provider value={{ setUserId, logEvent, incrementUserProperty, setUserProperties }}>
      {children}
    </AmplitudeContext.Provider>
  );
}

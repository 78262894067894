import { Database } from "../../types/db"
import { SecretClientContext } from "../../providers";
import { SupabaseClient } from "@supabase/supabase-js";
import { secretClient } from "../../utils";
import { useCallback, useContext } from "react";

export function useSecretClient() {
  const { secretClients, addSecretClient } = useContext(SecretClientContext);

  const getSecretClient = useCallback((secret: string): SupabaseClient<Database> => {
    var client = secretClients[secret];
    if (client) return client;

    client = secretClient(secret)
    addSecretClient(secret, client)

    return client
  }, [secretClients, addSecretClient])

  return { getSecretClient };
}

export function convertBytesToMbsOrKbs(filesize: number): string {
  let size = "";
  if (filesize >= 1048576) {
    size = (filesize / 1048576).toFixed(2) + " mb";
  } else if (filesize >= 1024) {
    size = (filesize / 1024).toFixed(2) + " kb";
  } else {
    size = filesize + " b";
  }
  return size;
}

export function filesEqual(file1: File, file2: File): boolean {
  return (
    file1.name === file2.name &&
    file1.size === file2.size &&
    file1.lastModified === file2.lastModified &&
    file1.type === file2.type
  );
}

export const startsWith = (str: string, word: string) => {
  return str.lastIndexOf(word, 0) === 0;
};

export const generateSharingSecret = (length = 8) => {
  return Math.random().toString(16).substr(2, length);
};

export function arrayMoveMutable<T>(array: Array<T>, fromIndex: number, toIndex: number) {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

    const [item] = array.splice(fromIndex, 1);
    array.splice(endIndex, 0, item);
  }
}

export function arrayMoveImmutable<T>(array: Array<T>, fromIndex: number, toIndex: number) {
  array = [...array];
  arrayMoveMutable(array, fromIndex, toIndex);
  return array;
}

export function partition<T>(arr: T[], fn: (val: T, i: number, arr: T[]) => boolean): T[][] {
  return arr.reduce(
    (acc: T[][], val, i, arr) => {
      acc[fn(val, i, arr) ? 0 : 1].push(val);
      return acc;
    },
    [[], []]
  );
}

import { unmute } from "../utils/unmuteiOS";
import React, { ReactNode, createContext, useEffect, useMemo } from "react";

type ContextValue = {
  audioContext: AudioContext | undefined;
};

export const AudioContextContext = createContext<ContextValue>({
  audioContext: undefined,
});

type Props = {
  children: ReactNode;
};

export function AudioContextProvider({ children }: Props) {
  const audioContext: AudioContext | undefined = useMemo(() => {
    if (typeof window !== "undefined") {
      const context = new window.AudioContext();
      if (context) unmute(context, true);
      return context;
    }
  }, []);

  useEffect(() => {
    return () => {
      if (audioContext !== undefined && audioContext.state !== "closed") {
        audioContext.close();
      }
    };
  }, [audioContext]);

  return <AudioContextContext.Provider value={{ audioContext }}>{children}</AudioContextContext.Provider>;
}

import { SongStateContext, initialChannelState } from "../../providers";
import { useContext } from "react";

export function useGlobalState() {
  const { songId, channelState, globalState, updateGlobalState } = useContext(SongStateContext);

  const setMasterVolume = (masterVolume: number) => {
    updateGlobalState({ ...globalState, masterVolume });
  };

  const soloCount = Object.values(channelState).filter(({ solo }) => solo).length;
  const muteCount = Object.values(channelState).filter(({ muted }) => muted).length;

  const globalSolo = soloCount > 0;

  return {
    songId,
    masterVolume: globalState.masterVolume,
    setMasterVolume,
    globalSolo,
    muteCount,
    soloCount,
  };
}

export function useChannelState(channelId: string) {
  const { channelState, updateChannelState } = useContext(SongStateContext);

  const state = channelState[channelId] || initialChannelState;

  const volume = state.volume;

  const setVolume = (volume: number) => {
    updateChannelState(channelId, {
      ...state,
      volume,
    });
  };

  const muted = state.muted;

  const toggleMuted = () => {
    updateChannelState(channelId, {
      ...state,
      muted: !muted,
    });
  };

  const solo = state.solo;

  const toggleSolo = () => {
    updateChannelState(channelId, {
      ...state,
      solo: !solo,
    });
  };

  return {
    volume,
    setVolume,
    muted,
    toggleMuted,
    solo,
    toggleSolo,
  };
}

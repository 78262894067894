import { updatePlaylistOrder } from "../../api";
import { useNotifications } from "..";

export function useReorderPlaylist() {
  const [addNotification] = useNotifications();

  const reorderPlaylist = async (id: string, songIds: string[]) => {
    try {
      await updatePlaylistOrder(id, songIds);
    } catch (error) {
      addNotification({
        type: "error",
        message: "Failed to reorder playlist",
        autohide: true,
      });

      throw error;
    }
  };

  return reorderPlaylist;
}

import { useRouter } from "next/router";

export function useQueryParam<T>(param: string, defaultValue: T): T {
  const router = useRouter();

  if (param in router.query) {
    return router.query[param] as T;
  }

  return defaultValue;
}

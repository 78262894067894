export const REGISTRATION_INVITE_CODE = "dyh9s";

export const EMAIL_RULES = {
  required: "Required",
  pattern: {
    value: /\S+@\S+\.\S+/,
    message: "Invalid email",
  },
};

export const PASSWORD_RULES = {
  required: "Required",
  minLength: {
    value: 10,
    message: "Must be at least 10 characters",
  },
};

export const DEFAULT_VOLUME = 0.7;

export const PURPOSES = [
  { value: "musical-theater", label: "Musical Theater" },
  { value: "band", label: "Band" },
  { value: "choral", label: "Choral" },
  { value: "instrumental", label: "Instrumental" },
  { value: "education", label: "Education" },
];

export const ORIGINS = [
  { value: "recommendation", label: "Recommended by friend or colleague" },
  { value: "social-media", label: "Social media" },
  { value: "web-search", label: "Web search (Google etc)" },
  { value: "article", label: "Article or blog post" },
];

import { enabledFeatureFlagsQuery } from "../api";
import { log } from "../utils";
import { useAuth } from "../hooks";
import React, { ReactNode, createContext, useCallback, useEffect, useState } from "react";

type ContextValue = {
  enabledFeatureFlags: string[];
  setReferenceUserId: (userId: string) => void;
};

export const FeatureFlagsContext = createContext<ContextValue>({
  enabledFeatureFlags: [],
  setReferenceUserId: (_: string) => { }
});

type Props = {
  children: ReactNode;
};

export function FeatureFlagsProvider({ children }: Props) {
  const { user } = useAuth();

  const [loaded, setLoaded] = useState<boolean>(false);
  const [enabledFeatureFlags, setEnabledFeatureFlags] = useState<string[]>([]);
  const [referenceUserId, setReferenceUserId] = useState<string | undefined>(undefined)

  useEffect(() => {
    const loadData = async (userId: string) => {
      const result = await enabledFeatureFlagsQuery(userId, referenceUserId);

      if (result.result === "success") {
        setEnabledFeatureFlags(result.keys);
        log("Feature flags enabled:", result.keys)
      } else {
        reportError(new Error(result.message));
      }
      setLoaded(true)
    };

    if (user && !loaded) {
      loadData(user.id);
    }
  }, [user, referenceUserId, setEnabledFeatureFlags, setLoaded, loaded]);

  const setReferenceUserIdWrapper = useCallback((id: string) => {
    log("referenceUserId updated:", id)
    setReferenceUserId(id)
    setLoaded(false)
  }, [setLoaded, setReferenceUserId])

  return <FeatureFlagsContext.Provider value={{ enabledFeatureFlags, setReferenceUserId: setReferenceUserIdWrapper }}>{children}</FeatureFlagsContext.Provider>;
}

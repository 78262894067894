import { FileObject } from "../../types";
import { getAudioMetadata, shortenUUID } from "../../utils";
import { insertChannels, insertRegions, insertSong, uploadRegionFile } from "../../api";
import { useAmplitude, useAudioContext, useAuth, useDeleteSong, useNotifications, useQueryParam, useTranscoder } from "..";
import { useRef } from "react";
import { v4 } from "uuid";
import sumBy from "lodash/sumBy";
import zip from "lodash/zip";

const TRANSCODE_PROGRESS_RATIO = 0.5
const UPLOAD_PROGRESS_RATIO = 0.5
const INVALID_KEY_CHAR_REGEX = /[^a-zA-Z0-9\.\-\*'\(\)\!\s]/g // https://docs.aws.amazon.com/AmazonS3/latest/userguide/object-keys.html

interface ChannelToUpload {
  id: string;
  label: string;
  songId: string;
  order: number;
  regions: RegionToUpload[]
  compressionPc: number;
}

interface RegionToUpload {
  regionId: string;
  key: string;
  file: File;
  codec: string | null;
  sampleRate: number | null;
  position: number;
}

const key = (userId: string, songId: string, regionId: string, filename: string) => {
  // Remove invalid characters, and replace whitespace with '-'
  const sanitisedFilename = filename.replace(INVALID_KEY_CHAR_REGEX, "").replace(/\s/g, "-")
  return `${userId}/songs/${songId}/regions/${regionId}-${sanitisedFilename}`
}

export function useUploadSong() {
  const { user } = useAuth();
  const { audioContext } = useAudioContext();
  const deleteSong = useDeleteSong();
  const { transcodeToRegions } = useTranscoder();
  const [addNotification] = useNotifications();
  const { logEvent, events, incrementUserProperty } = useAmplitude();
  const skipTranscode = useQueryParam<string>("skipTranscode", "0") == "1";

  const uploadedCount = useRef(0);

  const uploadSong = async (id: string, name: string, fileObjects: FileObject[], onProgress: (progress: number) => void) => {
    if (!user) {
      throw new Error("Not authenticated");
    }

    if (!audioContext) throw new Error("audioContext not initialized");

    const userId = user.id;

    var songCreated = false;

    try {
      const transcodeStartTime = new Date().getTime();

      let channelsToUpload: ChannelToUpload[];

      if (!skipTranscode) {
        try {
          const inputFiles = fileObjects.map(fo => fo.file);
          const onTranscodeProgress = (progress: number) => {
            onProgress(progress * TRANSCODE_PROGRESS_RATIO)
          }
          const codec = "mp3"
          const sampleRate = 44100
          const transcodedRegionsWithPositions = await transcodeToRegions(inputFiles, { codec, sampleRate }, onTranscodeProgress)

          channelsToUpload = zip(fileObjects, transcodedRegionsWithPositions).map(([inputFileObject, channelRegionsWithPositions], index) => {
            if (inputFileObject === undefined || channelRegionsWithPositions === undefined) {
              throw new Error("Post-transcode zipping failed")
            }

            const regionsToUpload = channelRegionsWithPositions.map(regionWithPosition => {
              const regionId = v4();

              const transcodedFile = regionWithPosition.file

              return {
                regionId,
                key: key(userId, id, regionId, transcodedFile.name),
                file: transcodedFile,
                codec,
                sampleRate,
                position: regionWithPosition.position,

              };
            })

            return {
              id: inputFileObject.id,
              label: inputFileObject.label,
              songId: id,
              order: index,
              // Just for tracking
              compressionPc: Math.floor((sumBy(regionsToUpload, r => r.file.size) / inputFileObject.file.size) * 100),
              regions: regionsToUpload
            }
          })
        } catch (error) {
          logEvent(events.upload.failTranscode({ songId: id, songName: name, trackCount: fileObjects.length }));
          throw error;
        }
      } else {
        channelsToUpload = fileObjects.map((fileObject, index) => {
          const regionId = v4();

          return {
            id: fileObject.id,
            label: fileObject.label,
            songId: id,
            order: index,
            compressionPc: 100,
            regions: [{
              regionId,
              key: key(userId, id, regionId, fileObject.file.name),
              file: fileObject.file,
              codec: null,
              sampleRate: null,
              position: 0,
            }]
          };
        });
      }

      const transcodeDurationSeconds = skipTranscode
        ? null
        : Math.ceil((new Date().getTime() - transcodeStartTime) / 1000);
      logEvent(
        events.upload.completeTranscode({
          songId: id,
          songName: name,
          transcodeDurationSeconds: transcodeDurationSeconds,
          compressionRatioPc: channelsToUpload.map(c => c.compressionPc),
        })
      );

      const uploadStartTime = new Date().getTime();

      const channels = channelsToUpload.map(c => {
        return {
          id: c.id,
          order: c.order,
          songId: c.songId,
          label: c.label,
        };
      })

      const regions = await Promise.all(channelsToUpload.flatMap(c => {
        return c.regions.map(async r => {
          const fileInfo = await getAudioMetadata(r.file, audioContext);

          const bitRate = Math.round((r.file.size * 8) / fileInfo.duration);

          return {
            id: r.regionId,
            channelId: c.id,
            duration: fileInfo.duration,
            sampleRate: r.sampleRate,
            channelCount: fileInfo.numberOfChannels,
            codec: r.codec,
            bitRate,
            size: r.file.size,
            position: r.position,
            key: r.key,
            filename: r.file.name,
            songId: id,
          };

        })
      }))

      await insertSong({ id, name, userId });

      songCreated = true;

      await insertChannels(channels);
      await insertRegions(regions);

      const totalRegionCount = channelsToUpload.flatMap(c => c.regions).length

      const onUploadProgress = () => {
        uploadedCount.current += 1;
        onProgress(TRANSCODE_PROGRESS_RATIO + (uploadedCount.current / totalRegionCount) * UPLOAD_PROGRESS_RATIO)
      }

      await Promise.all(channelsToUpload.flatMap(c => {
        return c.regions.map(async r => {
          await uploadRegionFile({ key: r.key, file: r.file });
          onUploadProgress()
        })
      }))

      const uploadDurationSeconds = Math.ceil((new Date().getTime() - uploadStartTime) / 1000);
      logEvent(
        events.upload.completeUpload({ songId: id, songName: name, uploadDurationSeconds: uploadDurationSeconds })
      );
      incrementUserProperty("songs");

      const redirect = `/song/${shortenUUID(id)}`;

      addNotification({
        type: "success",
        message: "Upload succeeded",
        autohide: true,
      });

      return redirect;
    } catch (error) {
      addNotification({
        type: "error",
        message: `Upload failed`,
        autohide: true,
      });

      // If song object was already created, clean up
      if (songCreated) { await deleteSong(id) }

      throw error;
    }
  };

  return uploadSong;
}
